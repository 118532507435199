import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import autoSyncImg from "../images/app/auto-sync.png"
import realTimeImg from "../images/app/maindashboard-historical-2.png"
import profitLossImg from "../images/app/profit-loss.png"
import customIndexImg from "../images/app/coin-index.png"
import coinRatingImg from "../images/app/coin-rating.png"
import coinNoteImg from "../images/app/coin-notes.png"
import omniImg from "../images/app/omni.png"
import mainImg from "../images/app/historical-performance.png"
import SEO from "../components/seo"

const features = [
  {title: 'Auto sync', imageUrl: autoSyncImg, description: 'Automatic sync with exchanges and blockchain addresses.'},
  {title: 'Real time tracking', imageUrl: realTimeImg, description: 'Get accurate real time information on your asset performance.'},
  {title: 'Profit & loss', imageUrl: profitLossImg, description: ''},
  {title: 'Custom index', imageUrl: customIndexImg, description: 'Simulate a portfolio index based on various strategies and test asset performance.'},
  {title: 'Personal coin rating', imageUrl: coinRatingImg, description: 'Easily capture your insights on each coin.'},
  {title: 'Encrypted journal', imageUrl: coinNoteImg, description: 'Add your personal notes. All data AES encrypted and stored locally.'},
  {title: 'Smart Calculator', imageUrl: omniImg, description: 'Computational intelligence for cryptocurrencies.'},
]

class Features extends React.Component {

  constructor() {
    super()
    this.state = {}
  }

  componentDidMount () {
    this.setState({
      width: window.innerWidth
    })
  }

  componentWillMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', this.handleWindowSizeChange)
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.handleWindowSizeChange)
    }
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth })
  }

  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const { width } = this.state
    const isMobile = width && width <= 769

    let featureImage = (feature) => {
      return (
        <div className="col" style={{'flex': '2'}}>
          <img src={feature.imageUrl} className="app-image" alt={feature.description}/>
        </div>
      )
    }

    let featureDescription = (feature) => {
      return (
        <div className="container col feature-description">
          <div className="col">
            <h2>{feature.title}</h2>
            <p>{feature.description} </p>
          </div>
          {/* <video autoPlay loop="" muted="" playsInline=""> */}
          {/*   <source src="calling.mp4" type="video/mp4"/> */}
          {/* </video> */}
        </div>
      )
    }

    let featureRow = (index, feature) => {
      if (isMobile || index % 2 === 0) {
        return (
          <React.Fragment>
            {featureImage(feature)}
            {featureDescription(feature)}
          </React.Fragment>
        )
      } else {
        return (
          <React.Fragment>
            {featureDescription(feature)}
            {featureImage(feature)}
          </React.Fragment>
        )
      }

    }

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title='Features'
          description='List all features of Invizi'
        />
        <section className="section-home-intro section-lead-intro">
          <div className="container row">
            <div className="col lead-section">
              <h1 className="lead">Privately track your assets.</h1>
              <p className="intro">
                Visualize, Analyze and Act
              </p>
            </div>
          </div>
          <img src={mainImg} className="app-image" alt="Invizi Main Dashboard"/>
        </section>
        <section className="feature-section">
          {features.map((feature, index) => {
            return (
              <div className="row" key={index}>
                {featureRow(index, feature)}
              </div>
            )
          })}
          <div className="row">
            <div className="col text-center" style={{'flex': '2'}}>
            </div>
            <div className="container col feature-description">
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

// document.getElementsByTagName('video')[0].onended = function () {
//   this.load();
//   this.play();
// };

export default Features

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
